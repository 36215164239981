<template>
  <div class="content-wrapper">
    <div id="registration-form" class="row justify-content-center wrapper">
      <div class="registration-box col-md-11 col-lg-11 col-xl-8">
        <div class="row">
          <div class="col-lg-9 col-md-12 col-sm-12 col-xs-12 welcome-box">
            <h1><strong>Willkommen</strong> bei EntwicklerHeld</h1>
            <p
              >Hier können Sie sich mithilfe Ihres persönlichen Start-Codes anmelden. Mit diesem können Sie einen
              Mitarbeiter-Account erstellen und diesen Ihrem Unternehmensprofil zuordnen. Geben Sie dazu Ihren
              persönlichen Start-Code in das Feld ein und klicken Sie "Registrieren"</p
            >
            <div v-if="registerCompanyRequest.loaded && registerCompanyRequest.error">
              <BaseError v-if="registerCompanyRequest.error.status === 400">
                Der Registrierungscode ist nicht gültig. Bitte kontaktieren Sie das EntwicklerHeld-Team.
              </BaseError>
              <BaseError v-if="registerCompanyRequest.error.status !== 400">
                Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie das EntwicklerHeld-Team.
              </BaseError>
            </div>
            <div v-if="registrationCodeRequest.loaded && registrationCodeRequest.error">
              <BaseError v-if="registrationCodeRequest.error.status === 404">
                Der Registrierungscode ist unbekannt. Bitte kontaktieren Sie das EntwicklerHeld-Team.
              </BaseError>
              <BaseError v-if="registrationCodeRequest.error.status === 500">
                Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie das EntwicklerHeld-Team.
              </BaseError>
            </div>
            <div v-if="registrationCodeRequest.loaded && !registrationCodeRequest.error">
              <BaseError v-if="registrationCodeRequest.apiData.used">
                Der Registrierungscode wurde schon benutzt. Bitte kontaktieren Sie das EntwicklerHeld-Team für einen
                neuen Code.
              </BaseError>
            </div>
            <div v-if="!isSuccessPage" class="form-wrapper">
              <input
                v-model="registrationCode"
                placeholder="Start-Code"
                type="text"
                class="col-lg-6 col-sm-12"
                @input="handleRegistrationCodeInput"
              />
              <button class="cta-button" :disabled="isRegistrationButtonDisabled" @click="register">
                <span
                  class="fa fa-fw"
                  :class="{
                    'fa-pencil-square-o': !isRegistrationButtonLoading,
                    'fa-spinner': isRegistrationButtonLoading,
                    'fa-spin': isRegistrationButtonLoading,
                  }"
                ></span>
                Registrieren</button
              >
            </div>
            <div v-if="isSuccessPage" class="alert alert-success">
              Die Registrierung war erfolgreich, Sie können sich nun mit Ihren Zugangsdaten einloggen!
            </div>
          </div>

          <div
            class="col-md-3 col-lg-3"
            style="background-color: rgba(241, 241, 244, 0.4); position: relative; background-position: right 100px; background-image: url('https://res.cloudinary.com/dspi5zyaa/image/upload/w_50/eh-website/eh_logo.png');"
          >
            <svg
              id="Wave"
              style="position: absolute; height: 100%; z-index: 200; left:0;"
              data-name="Wave"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="85 80 134.24 500"
            >
              <g class="cls-2">
                <g class="cls-5">
                  <g class="cls-6">
                    <path
                      class="cls-4"
                      d="M938.55,107c-19.92,39.84-25.74,72.51-25.47,98.48.45,41.56,16.3,46.26,19,99.59,3.12,60.61-15.28,95.12-13.38,179.26.79,34.94,4,30.19,5.15,73,1.73,64.38-5.33,80.77-3.35,139.43,1.66,49,7,50.1,6.43,89.63-.6,41.43-6.61,49.63-8.23,96.27-1.49,43.25,2.87,59.5,2.58,87.42-.77,72.37-32.2,163.57-53.25,145-44.52-39.37-81.81-604.51-31.64-868.65C842,217.31,862,112,895.85,89.27,914.26,76.94,929.67,93.78,938.55,107Z"
                      transform="translate(-804.31 -110.98)"
                    />
                  </g>
                </g>
                <g class="cls-3">
                  <path
                    class="cls-4"
                    d="M930.7,1106.07c-20-39.4-25.8-71.71-25.52-97.39.44-41.1,16.32-45.75,19.07-98.48,3.12-59.94-15.31-94.06-13.4-177.27.79-34.55,4-29.85,5.15-72.22,1.74-63.65-5.34-79.87-3.35-137.87,1.66-48.4,7-49.54,6.45-88.63-.61-41-6.64-49.08-8.25-95.2-1.5-42.77,2.87-58.83,2.58-86.44-.77-71.58-32.27-161.76-53.36-143.35-44.62,38.93-82,597.78-31.71,859,5.54,28.77,25.59,133,59.55,155.38C906.36,1135.76,921.8,1119.11,930.7,1106.07Z"
                    transform="translate(-804.31 -84.98)"
                  />
                </g>
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { services } from "@/main";
import { eventBus } from "@/eventbus";
import { navigateTo } from "@/utils/routing";
import { getRegistrationCode, isRegistrationCodePresent } from "@/utils/registrationCode";
import { isCookieConsentSet } from "@/services/Utilities";
import BaseError from "@/components/base/BaseError";

// https://stackoverflow.com/questions/7905929/how-to-test-valid-uuid-guid
const UUID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

export default {
  components: { BaseError },
  props: {},
  data() {
    return {
      registrationCodeRequest: { loaded: null, error: null },
      registerCompanyRequest: { loaded: null, error: null },
      checkSessionResult: { authResult: null, error: null },
      registrationCode: null,
      isRegistrationButtonDisabled: true,
      isRegistrationButtonLoading: false,
      isSuccessPage: "success" in this.$route.query,
    };
  },
  computed: {},
  watch: {
    "registrationCodeRequest.loaded": function() {
      if (!this.registrationCodeRequest.loaded) {
        return;
      }
      if (this.registrationCodeRequest.error) {
        this.isRegistrationButtonDisabled = true;
        return;
      }
      if (this.registrationCodeRequest.apiData.used) {
        this.isRegistrationButtonDisabled = true;
        return;
      }
      this.register();
    },
    "registerCompanyRequest.loaded": function() {
      if (!this.registerCompanyRequest.loaded) {
        return;
      }

      if (this.registerCompanyRequest.error) {
        this.isRegistrationButtonDisabled = false;
        this.isRegistrationButtonLoading = false;
        return;
      }

      this.isRegistrationButtonDisabled = true;
      this.isRegistrationButtonLoading = true;
      this.checkSessionResult = services.authenticationService.checkSession(this.checkSessionResult);
    },
    "checkSessionResult.authResult": function() {
      if (this.checkSessionResult.authResult) {
        this.isRegistrationButtonDisabled = false;
        this.isRegistrationButtonLoading = false;
      }
    },
    "checkSessionResult.error": function() {
      if (this.checkSessionResult.error) {
        services.authenticationService.logout("/register_with_code?success=1");
      }
    },
  },
  mounted() {
    if (isRegistrationCodePresent(this.$route.query)) {
      this.isRegistrationButtonDisabled = false;
      this.registrationCode = getRegistrationCode(this.$route.query);
      this.registrationCodeRequest = services.companyService.getRegistrationCode(this.registrationCode);
    }

    eventBus.$on("userLoggedIn", async () => {
      let result = await services.companyService.getEmployeeByUserIdPromise();
      if (!result.error && result.apiData.isRegistered) {
        navigateTo(this.$router, { name: "profile" });
        return;
      }

      this.registerCompanyRequest = services.companyService.registerCompany({}, this.registrationCode);
    });
  },
  beforeDestroy() {
    eventBus.$off("userLoggedIn");
  },
  methods: {
    register: function() {
      this.isRegistrationButtonDisabled = true;
      this.isRegistrationButtonLoading = true;
      if (!services.authenticationService.isAuthenticated() && isCookieConsentSet()) {
        services.authenticationService.signUp();
        document.getElementsByClassName("auth0-lock-close-button")[0].remove();
        return;
      }
      this.registerCompanyRequest = services.companyService.registerCompany({}, this.registrationCode);
    },
    handleRegistrationCodeInput() {
      if (this.registrationCode.trim() === "") {
        this.isRegistrationButtonDisabled = true;
        return;
      }

      if (this.registrationCode.match(UUID_REGEX) === null) {
        this.isRegistrationButtonDisabled = true;
        return;
      }

      this.isRegistrationButtonDisabled = false;
      this.registrationCodeRequest = { loaded: null, error: null };
      this.registerCompanyRequest = { loaded: null, error: null };
    },
  },
};
</script>

<style lang="scss" scoped>
.registration-box {
  border-radius: 1rem;
  max-width: 1150px;
}

#registration-form .active .wizard-icon {
  color: #4fadd8;
}

#registration-form {
  background: linear-gradient(0deg, transparent, #f5f4f7, #f5f4f7);
  /* This is to make the gray background fit with the paddings of the parent .wrapper.container-fluid*/
  width: calc(100% + 30px);
}

#registration-form .wizard-progress-with-circle {
  background-color: rgba(255, 255, 255, 0.2);
}

.registration-box {
  background-color: white;
  box-shadow: $elevation3;
  box-sizing: border-box;
  border-radius: 1rem;
  overflow: hidden;

  margin: 2rem;
  margin-top: 4rem;
}

.content-wrapper {
  background: linear-gradient(0deg, transparent, #f5f4f7, #f5f4f7);
}

input:hover,
input:active {
  box-shadow: 0 2px 14px rgba(0, 0, 0, 0.22);
}

input,
textarea {
  outline: 0;
  box-shadow: 0 2px 14px rgba(0, 0, 0, 0.12);
  border: 0;
  border-radius: 10px;
  padding: 1rem 1rem;
  margin-right: 1rem;
  /*width: 60%;
  min-width: 150px;*/
}

.cta-button:hover {
}

.cta-button {
  padding: 1rem 1rem;
  background-color: #509588;
  color: #fff;
  font-weight: 700;
  font-size: 1.1rem;
  border-radius: 10px;
  border-width: 0;

  transition: background-color 0.3s linear;

  &:hover {
    background-color: #478478;
    filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.2));
  }

  &:disabled {
    background-color: $hover-grey;
  }

  &:disabled:hover {
    filter: none;
  }
}

.form-wrapper {
  margin: 2rem auto;
}
.welcome-box {
  padding: 2.5rem;
}

.company-logo-wrapper {
  margin: 1rem auto;
  width: 60%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
}
.company-logo {
  border-radius: 50%;
  width: 110px;
  height: 110px;
  -o-object-fit: contain;
  object-fit: contain;
  filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.2));
  padding: 5px;
  background-color: white;
  margin: 1rem auto;
}
</style>

<style>
.cls-1 {
  fill: none;
}

.cls-2 {
  clip-path: url(#clip-path);
}

.cls-3 {
  clip-path: url(#clip-path-2);
}

.cls-4 {
  fill: #fff;
}

.cls-7 {
  fill: #3e93e5;
}

.cls-5 {
  opacity: 0.4;
}

.cls-6 {
  clip-path: url(#clip-path-3);
}
</style>
